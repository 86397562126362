<template>
    <div class="row d-flex justify-content-center">
        <div class="col-md-12">
          <ValidationObserver ref="card">
            <card title="Venue Drink List">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5">
                    <ValidationProvider
                      name="customer"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        name="Customer"
                        :error="failed ? 'The Customer field is required': null"
                        :hasSuccess="passed"
                        label="Customer Name"
                      >
                        <el-select
                            class="select-primary"
                            v-model="customer"
                            placeholder="Customer Name"
                            filterable
                            clearable
                            @change="getVenues(customer.id)"
                        >
                            <el-option
                                class="select-primary"
                                v-for="item in customers"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                      </fg-input>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-5">
                    <ValidationProvider
                      name="venue"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        name="Venue"
                        :error="failed ? 'The Venue field is required': null"
                        :hasSuccess="passed"
                        label="Venue"
                      >

                        <el-select
                          class="select-primary"
                          v-model="venue"
                          placeholder="Choose Venue"
                          filterable
                          clearable
                          @change="getVenueDrinks()"
                        >
                          <el-option
                            class="select-primary"
                            v-for="item in venues"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </card>
          </ValidationObserver>
          <card title="Choose venue drinks from master list">
            <div class="col-md-12">
                <div class="row">
                    <pg-table :tableColumns="PgTable.tableColumns"
                            :tableData="PgTable.tableData"
                            :propsToSearch="PgTable.propsToSearch"
                            :handleSelectionChange="PgTable.handleSelectionChange"
                            :withMultiSelection="true"
                            :paginated="true"
                            :searchable="true">
                    </pg-table>
                </div>
            </div>
          </card>
          <button
              type="submit"
              class="btn btn-fill btn-info custom-button"
              @click="saveChanges"
          >
            Save Changes
          </button>
        </div>
    </div>
</template>


<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import PgTable from 'src/pages/Dashboard/Tables/PaginatedTables.vue';
import { Table, TableColumn, Select, Option } from "element-ui";
import Swal from "sweetalert2";

extend("required", required);
export default {
  components: {
    PgTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
        PgTable:{
            tableColumns: [
                {
                prop: "name",
                label: "Name",
                minWidth: 70,
                },
                {
                prop: "version",
                label: "Version",
                minWidth: 50,
                },
                {
                prop: "type",
                label: "Type",
                minWidth: 70,
                },
            ],
            tableData: [],
            propsToSearch: ['name', 'version'],
            handleSelectionChange: this.handleSelectionChange
        },
      selectedDrinks: [],
      customers: [],
      customer: "",
      venues: [],
      venue: "",
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val)
      if(val.length > 0){
        val.forEach((element) => {
            if (this.selectedDrinks.indexOf(element) === -1) {
            this.selectedDrinks.push(element);
            }
        });
        this.selectedDrinks = val;
      }
    },
    getMsterDrinks() {
      const axios = require("axios");
      axios.get("https://portal.barvision.com/masterDrinks", {headers: {Authorization: "Bearer " + this.$cookies.get('Token')}}).then((response) => {
        this.PgTable.tableData = response.data;
      });
    },
    getCustomers() {
      const axios = require("axios");
      axios.get("https://portal.barvision.com/customers", {headers: {Authorization: "Bearer " + this.$cookies.get('Token')}}).then((response) => {
        this.customers = response.data;
      });
    },
    getVenues() {
      const axios = require("axios");
      axios
        .get("https://portal.barvision.com/customerVenues/" + this.customer, {headers: {Authorization: "Bearer " + this.$cookies.get('Token')}})
        .then((response) => {
          this.venues = response.data;
        });
      this.venue = "";
    },
    getVenueDrinks() {
      const axios = require("axios");
      axios
        .get("https://portal.barvision.com/venueDrinks/" + this.venue, {headers: {Authorization: "Bearer " + this.$cookies.get('Token')}})
        .then((response) => {
          this.PgTable.tableData = response.data;
        });
      this.venue = "";
    },
    saveChanges() {
      if (!(this.customer)) {
        Swal.fire({
          icon: 'error',
          title: "Select Customer & Venue",
          text: "Please, Make sure to select the customer and venue",
          showConfirmButton: false,
        });
      }
      else if (!(this.venue)) {
        Swal.fire({
          icon: 'error',
          title: "Select Venue",
          text: "Please, Make sure to select the venue",
          showConfirmButton: false,
        });
      }
      else if (!this.selectedDrinks.length){
        Swal.fire({
          icon: 'error',
          title: "Empty drinks list",
          text: "Please, Make sure to select and edit drinks",
          showConfirmButton: false,
        });
      }
      else {
        console.log(this.customer);
        console.log(this.venue);
        console.log(this.selectedDrinks);
        Swal.fire({
          icon: 'success',
          title: "Done",
          text: "Done inserting drinks to the selected venue",
          showConfirmButton: false,
        });
      }
    }
  },
  mounted() {
    this.getMsterDrinks();
    this.getCustomers();
  },
};
</script>
<style>
.custom-button {
    font-family: Arial;
    float: right;
    font-size: 1.005rem;
    text-align: right;
    margin: 0.005px;
    cursor: pointer;
}
</style>
